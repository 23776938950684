.highcharts-crosshair {
    pointer-events: none;
    stroke-width: 1px;
    stroke: #595959;
}

.highcharts-series-inactive {
    opacity: 1 !important;
}

.tooltip-chart-card {
    position: relative;
    background: #ffffff;
    border-radius: 15px;
    border: 1px solid #bfbfbf;
    padding: 24px;
    display: flex;
    flex-direction: column;
    width: 300px;

    .title {
        color: #000;
        font-family: 'TT Commons Pro';
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 125% */
        margin-bottom: 13px;
    }

    .subtitle {
        color: #000;
        font-family: 'TT Commons Pro';
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px; /* 133.333% */
        letter-spacing: 0.24px;
        text-transform: uppercase;
        margin-bottom: 4px;
    }

    .legend {
        display: flex;
        align-items: center;
        margin-bottom: 6px;

        .amount {
            color: #000;
            font-variant-numeric: lining-nums tabular-nums;
            font-family: 'TT Commons Pro';
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px; /* 125% */
        }

        .left {
            display: flex;
            align-items: center;
            flex: 1;
            gap: 7px;
        }

        .line {
            width: 22px;
            height: 3px;
            flex-shrink: 0;
            border-radius: 100px;
        }

        .chip {
            display: flex;
            padding: 4px 12px;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            border-radius: 100px;
            background: #ddeaff;
            color: #000;
            font-family: 'TT Commons Pro';
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px; /* 133.333% */
            letter-spacing: 0.12px;
        }

        .bar {
            display: block;
            width: 22px;
            height: 0px;
            flex-shrink: 0;
            border-radius: 100px;
            
        }
        .low-bar{
            background-image: url('../../../assets/images/pdf/ppgLow2px.png');
            background-repeat: no-repeat;
            background-size: 22px 3px;
            width: 22px;
            height: 3px;
        }

        .name {
            color: #000;
            font-variant-numeric: lining-nums tabular-nums;
            font-family: 'TT Commons Pro';
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px; /* 138.462% */
        }
    }
}

.highcharts-plot-line-label {
    width: 112px;
    text-align: center;
}

.plot-title {
    color: #000;
    font-family: 'TT Commons Pro';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 166.667% */
    background: #ffffff;
    margin-bottom: 2px;
    display: block;
}


.custom-legend{
    display: flex;
    width: 100%;
    // justify-content: flex-end;
    gap: 32px;
    margin-bottom: 32px;

    .left {
        display: flex;
        align-items: center;
        gap: 7px;
    }

    .line {
        width: 25px;
        height: 4px;
        flex-shrink: 0;
        border-radius: 100px;
    }

    .bar {
        display: block;
        width: 20px;
        height: 0px;
        flex-shrink: 0;
        border-radius: 100px;
    }

    .grid{
        display: grid;
    }
}
