.highcharts-crosshair {
    pointer-events: none;
    stroke-width: 1px;
    stroke: #595959;
}

.highcharts-series-inactive {
    opacity: 1 !important;
}

.tooltip-chart-card {
    position: relative;
    background: #ffffff;
    border-radius: 15px;
    border: 1px solid #bfbfbf;
    padding: 24px;
    display: flex;
    flex-direction: column;
    width: 300px;

    .title {
        color: #000;
        font-family: 'TT Commons Pro';
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        /* 125% */
    }

    .subtitle {
        color: #000;
        font-family: 'TT Commons Pro';
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
        /* 133.333% */
        letter-spacing: 0.24px;
        text-transform: uppercase;
        margin-bottom: 4px;
    }

    .legend {
        display: flex;
        align-items: center;
        margin-bottom: 6px;

        .amount {
            color: #000;
            font-variant-numeric: lining-nums tabular-nums;
            font-family: 'TT Commons Pro';
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
            /* 125% */
        }

        .left {
            display: flex;
            align-items: center;
            flex: 1;
            gap: 7px;
        }

        .line {
            width: 20px;
            height: 4px;
            flex-shrink: 0;
            border-radius: 100px;
        }

        .chip {
            display: flex;
            padding: 4px 12px;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            border-radius: 100px;
            background: #ddeaff;
            color: #000;
            font-family: 'TT Commons Pro';
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
            /* 133.333% */
            letter-spacing: 0.12px;
        }

        .bar {
            display: block;
            width: 32px;
            height: 12px;
            flex-shrink: 0;
            border-radius: 100px;
        }

        .name {
            color: #000;
            font-variant-numeric: lining-nums tabular-nums;
            font-family: 'TT Commons Pro';
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;
            /* 138.462% */
        }
    }
}

.highcharts-plot-line-label {
    width: 112px;
    text-align: center;
}

.plot-title {
    color: #000;
    font-family: 'TT Commons Pro';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 166.667% */
    background: #ffffff;
    margin-bottom: 2px;
    display: block;
}

.highcharts-legend-item rect {
    visibility: hidden;
}

.legend-cont {
    display: flex;
    justify-content: center;
    align-items: center;

    .legend-bar {
        display: block;
        width: 32px;
        height: 12px;
        flex-shrink: 0;
        border-radius: 100px;
    }
    
    .legend-name {
        padding-left: 8px;
        padding-right: 6px;
        color: rgb(51, 51, 51);
        cursor: pointer;
        font-size: 16px;
        text-decoration: none;
        font-weight: 500;
        line-height: 20px;
    }
}