* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

svg {
    fill: currentColor;
    color: currentColor;
}

.MuiAccordionSummary-expandIconWrapper.Mui-expanded {
    transform: rotate(90deg) !important;
}
.MuiAccordion-root.Mui-expanded {
    margin: 0 !important;
}

.hantzIframe {
    height: 130vh;
    width: 124vw;
    zoom: 0.8;
}